import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import type { AppInstallTrackingCollection } from './app-install-tracking.types';

export function appInstallTrackingBackend(): Backend<AppInstallTrackingCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: `users/0.1/app_installs/`,
      params: {
        users: getQueryParamValue(query, 'userId'),
      },
    }),
    push: (authUid, appInstallTracking) => ({
      endpoint: `users/0.1/app_installs/${appInstallTracking.deviceId}/`,
      payload: {
        platform_type: appInstallTracking.platformType,
      },
    }),
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}
